import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Wilhelm Joseph Behr (1775–1851)</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <p>
                                Behr wurde 1818 als Vertreter der Universität Würzburg in die bayerische Ständeversammlung
                                gewählt, wo er zu einem führenden Kopf der liberalen Gruppierung wurde.
                                1821 wurde Behr Bürgermeister von Würzburg, was die Regierung zum Anlass nahm, ihm Professur und
                                Abgeordnetenmandat zu entziehen. Für seine Rede beim Gaibacher Fest 1832 wurde er zu einer
                                mehrjährigen Haftstrafe verurteilt. Begnadigungsversuche wurden abgelehnt, erst 1847 wurde der
                                Rest der Strafe erlassen. 1848 wurde er in die deutsche Nationalversammlung in der Frankfurter
                                Paulskirche gewählt, wo er jedoch aus Altersgründen nur noch kurz wirken konnte.
                            </p>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery2"
                                   href={"/images/Gaibach/gaibach_bild_0007.jpg"}
                                   data-glightbox="description: .custom-desc1_6; descPosition: bottom;">
                                    <img src={"/images/Gaibach/gaibach_bild_0007.jpg"} alt="Wilhelm Joseph Behr, 1819"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_6">
                                    <h2>Wilhelm Joseph Behr, 1819</h2>
                                    <p className="copy">
                                        Gemälde von Johann Georg Hirschmann
                                        Abb. Museum für Franken – Staatliches Museum für Kunst- und Kulturgeschichte in
                                        Würzburg, Leihgabe der Stadt Würzburg im Museum für Franken in Würzburg
                                    </p>
                                </div>
                                <figcaption>
                                    Wilhelm Joseph Behr, 1819
                                </figcaption>
                            </figure>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
